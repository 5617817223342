import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import {
  Alert,
  Box,
  Card,
  Container,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { Code, Preview } from "@mui/icons-material";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import AppbarComponent from "../../Services/AppbarComponent";
import { WSSuccessAlert, WSWarningAlert } from "../../Services/WSAlert";

const AddEditMailTemplate = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [data, setData] = useState({ MailTemplate: {} });
  const [mailGroups, setMailGroups] = useState([]);
  const roles = useSelector((state) => state.auth.AssignRole ?? []);
  const [tabValue, setTabValue] = useState(0);
  const Roles = useSelector((s) => s.auth.AssignRole ?? {});

  const [bData] = useState([
    {
      title: "Manage Email Template",
      hrefLink: "ManageMail",
    },
    {
      title:
        (state?.id || 0 || 0) == 0 ? "Add Mail Template" : "Edit Mail Template",

      hrefLink: "#",
    },
  ]);
  const id = state?.id || 0;

  // Fetch mail template details
  const fetchMailTemplate = async () => {
    try {
      const mailTemplate = await WebService({
        endPoint: `CommonUtility/Edit/staticemailtemplate?TemplateId=${id}`,
        dispatch,
      }).then((response) => (response.length > 0 ? response[0] : {}));
      setData({ MailTemplate: mailTemplate });
    } catch (error) {
      console.error("Error fetching mail template:", error);
    }
  };

  // Fetch mail groups
  const getMailGroups = async () => {
    try {
      const groups = await WebService({
        endPoint: "CommonUtility/fetchAll/StaticGroups",
        dispatch,
      }).then((response) =>
        response.map((item) => ({
          text: item.GroupName,
          value: item.StaticGroupId,
        }))
      );
      setMailGroups(groups);
    } catch (error) {
      console.error("Error fetching mail groups:", error);
    }
  };

  // Handle form submission
  const onSubmit = async () => {

    try {
        await WebService({
            endPoint: `CommonUtility/duplicate/check/?tableName=staticemailtemplate&fieldName=Title&fieldValue=${data.MailTemplate.Title}`,
            dispatch,
          }).then(res=>{
            if(res)
            { 
                WSWarningAlert('','Title Name already Exist, Please Choose a different one')
                return
            }
          }).catch(err=>{
            console.log(err)
            return
          })

      if (!data?.MailTemplate?.TemplateId) {
        await WebService({
          endPoint: "CommonUtility/staticemailtemplate",
          body: data.MailTemplate,
          dispatch,
        });
      } else {
        await WebService({
          endPoint: `CommonUtility/staticemailtemplate?TemplateId=${data?.MailTemplate?.TemplateId}`,
          method: "PUT",
          body: data.MailTemplate,
          dispatch,
        });
      }
      WSSuccessAlert('','Template saved')
      props.callBackEvent?.();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Initialize data on component mount
  useEffect(() => {
    fetchMailTemplate();
  }, []);

  useEffect(() => {
    getMailGroups();
  }, []);
  return (
    <Container fluid className="base-container ">
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div>
          <h5 className="ms-4 mt-2">
            {(data?.MailTemplate?.TemplateId || 0) === 0
              ? "Add Mail Template"
              : "Edit Mail Template"}
          </h5>
          <div className="ms-4">
            <BreadcrumbsComponent bData={bData} />
          </div>
        </div>
      </div>

      <AppbarComponent
        title={"add/edit mail template"}
        isSearchRequired={false}
        isAddButtonRequired={true}
        Exception={true}
      />
      <Card className="mt-2">
        <Form defaultValues={data.MailTemplate} onSubmit={onSubmit}>
          <div className="row px-4">
            <div className="col-md-12">
              <FormInputText
                label="Title *"
                name="Title"
                value={data.MailTemplate?.Title || ""}
                isRequired={true}
                setValue={(e) => {
                  console.log(e);
                  setData((prev) => ({
                    ...prev,
                    MailTemplate: { ...prev.MailTemplate, Title: e },
                  }));
                }}
                type="text"
                

              />
            </div>
            <div className="col-md-12">
              <FormInputDropdown
                name="Status"
                value={data.MailTemplate?.Status || ""}
                isRequired={true}
                ddOpt={StandardConst.ActiveInActiveStatusIdDropdown}
                setValue={(e) =>
                  setData((prev) => ({
                    ...prev,
                    MailTemplate: { ...prev.MailTemplate, Status: Number(e) },
                  }))
                }
                
                label="Status *"
              />
            </div>
            <div className="col-md-12">
              {Roles[0] === StandardConst.SystemRole.SuperAdmin && (
                <FormInputDropdown
                  name="IsReserved"
                  ddOpt={StandardConst.YesOrNoReserved}
                  label="Reserved *"
                  value={data.Roles?.IsReserved}
                  isRequired={true}

                ></FormInputDropdown>
              )}
            </div>

            <div className="col-md-12">
              <FormInputText
                label="SQL Query"
                name="SqlQuery"
                value={data.MailTemplate?.SqlQuery || ""}
                type="text"
                isRequired={true}
                setValue={(e) =>
                  setData((prev) => ({
                    ...prev,
                    MailTemplate: { ...prev.MailTemplate, SqlQuery: e },
                  }))
                }
              />
            </div>
            {roles[0] === StandardConst.SystemRole.SuperAdmin && (
              <div>
                <FormInputDropdown
                  label="Add it to a group"
                  name="MailTemplateGroupId"
                  ddOpt={mailGroups}
                  setValue={(e) =>
                    setData((prev) => ({
                      ...prev,
                      MailTemplate: {
                        ...prev.MailTemplate,
                        MailTemplateGroupId: e,
                      },
                    }))
                  }
                />
                <div className="d-flex flex-row justify-content-end">
                <span>Couldn't find the group ? </span>
                  <Link to="/ManageGroups" className="text-primary">add new</Link>
                </div>
              </div>
            )}
          </div>

          {/* Email Content Section */}
          <Alert className="rounded-0" severity="info">
            Email To Be Sent{" "}
          </Alert>
          <Box className="px-4">
            <div className="d-flex flex-lg-row flex-md-row  mt-4 flex-column justify-content-between gap-1">
              <div className="col-md-6 ">
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Email Subject"
                  value={data.MailTemplate.Subject}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      MailTemplate: {
                        ...prev.MailTemplate,
                        Subject: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </div>
            {/* Tabs */}
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
            >
              <Tab label="HTML Code" icon={<Code />} />
              <Tab label="Email Preview" icon={<Preview />} />
            </Tabs>

            {/* Tab Panels */}
            {tabValue === 0 && (
              <textarea
                rows={20}
                value={data.MailTemplate?.Body || ""}
                placeholder={`Write email content in HTML. Use placeholders like @name.`}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    MailTemplate: {
                      ...prev.MailTemplate,
                      Body: e.target.value,
                    },
                  }))
                }
              />
            )}
            {tabValue === 1 && (
              <div className="border mt-2">
                {data.MailTemplate?.Body ? (
                  <iframe
                    title="html-preview"
                    className="w-100"
                    height="600px"
                    srcDoc={data.MailTemplate.Body}
                    frameBorder="0"
                  />
                ) : (
                  <p>No HTML content available.</p>
                )}
              </div>
            )}
          </Box>
          <div className="d-flex justify-content-end my-1">
            <button
              className="btn btn-primary"
              id="btnSubmit"
              variant="outline-primary"
              type="submit"
            >
              {(data?.MailTemplate?.TemplateId || 0) === 0
                ? "Submit"
                : "Save Changes"}
            </button>
          </div>
        </Form>
      </Card>
    </Container>
  );
});

export default AddEditMailTemplate;
