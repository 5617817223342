import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { WebService } from "../../Services/WebService";
import { Form, FormAutoCompleteDropdownCard, FormInputDropdown, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Alert, TextField, Autocomplete } from "@mui/material";
import Topbar from "../../Services/TopbarComponent";
import { format } from "date-fns/esm";
import { PageInfo } from "../PageInfo";
import RichTextBox from "../../Services/RichTextBox";
import { chain } from "underscore";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import { el } from "date-fns/locale";

const AddEditJobPosting = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    PageInfo({ pageTitle: "Post A Job" });
    const { state } = useLocation();
    const [jobId, setJobId] = useState(null);
    const [jobData, setJobData] = useState({});
    const [jobLocationTypeState, setJobLocationTypeState] = useState(null);
    const [SpacificLocationforEmployeeRequired,setSpacificLocationforEmployeeRequired]=useState(null)
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [salaryRange, setSalaryRange] = useState('Range');
    const [staticJobTypesData, setStaticJobTypesData] = useState([]);
    const [selectedJobType, setSelectedJobType] = useState([]);
    const [autoErrorShow, setAutoErrorShow] = useState(false);
    const [minSalary, setMinSalary] = useState(0);
    const [maxSalary, setMaxSalary] = useState(0);
    const [applicantIsVisibaleToCustomer, setApplicantIsVisibaleToCustomer] = useState(false);

    const EmployeeId = useSelector(s => s.auth.LoggedUser);
    const UserId = useSelector(s => s.auth.LoggedCustomer);
    const [apiLocationData, setApiLocationData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState({});
    const [locationErrorShow, setLocationErrorShow] = useState(false);
    const [LocationDefaultValue, setLocationDefaultValue] = useState({});
    const [LocationLoading,setLocationLoading]=useState(false)

    const selectedJobLocation = (event, selectedOptions) => {
        if(selectedOptions !== null) {
            setLocationErrorShow(false) 
            setSelectedLocation(selectedOptions);
        } else{
            setLocationErrorShow(true);
            setSelectedLocation({});
        }
    };
    const fetchLocation = (event, SearchCity) => {
        setLocationLoading(true)
        if(SearchCity.length > 2){
            fetch(`https://nominatim.openstreetmap.org/search?q=${SearchCity}&format=json&addressdetails=1`, {
              method: 'GET'
            })
            .then(response => response.json())
            .then(data => {
                setApiLocationData(data);
            })
            .finally(() => {
                setLocationLoading(false)
              });
        }
    };
    

    const [bData] = useState([
        {
            title: "Recruitment",
            hrefLink: "#",
        },
        {
            title: "Post a job",
            hrefLink: "#",
        },
    ]);

    const getJobsData = async (jobId) => {
        const jobsData = await WebService({
            endPoint: `CommonUtility/Edit/jobs?JobId=${jobId}`,
            dispatch,
        });


        if(jobsData.length > 0){

            if(jobsData[0].CustomerId !== null){
                const CustomerDetail = await WebService({
                    endPoint: `CommonUtility/Edit/customerdetails?CustomerId=${jobsData[0].CustomerId}`,
                }).then(res => ({
                    uniqueId: res[0].CustomerId,
                    heading: res[0].Name, 
                    description: '', 
                    avatar: ''
                }))
                jobsData[0].CustomerId = CustomerDetail;
            }
            setJobData(jobsData[0]);
            setJobLocationTypeState(jobsData[0].JobLocationType);
            setSalaryRange(jobsData[0].SalaryRange);
            setMinSalary(jobsData[0].MinimumSalary);
            setMaxSalary(jobsData[0].MaximumSalary);
            setJobDescriptionValue(jobsData[0].Description)
            setSpacificLocationforEmployeeRequired(jobsData?.[0]?.SpacificLocationforEmployeeRequired || 0);
            setLocationDefaultValue({ display_name: jobsData?.[0]?.Location || "india" });
            setSelectedLocation(jobsData?.[0]?.Location)

        }
        await WebService({
            endPoint: `Recruitment/fetchAdditionalJobData?JobId=${jobId}`,
            dispatch,
        }).then((c) => {

            if(c.length > 0) {
                c.map(item => {
                    if(item.JobTypeData !== undefined && item.JobTypeData.length > 0){
                        setSelectedJobType(item.JobTypeData);
                    }
                    if(item.JobScheduleData !== undefined && item.JobScheduleData.length > 0){
                        setSelectedJobSchedule(item.JobScheduleData);
                    }

                    if(item.JobBenefitData !== undefined && item.JobBenefitData.length > 0){
                        setSelectedJobBenefit(item.JobBenefitData);
                    }
                    if(item.JobSupplementalPayData !== undefined && item.JobSupplementalPayData.length > 0){
                        setSelectedJobSupplementalPay(item.JobSupplementalPayData);
                    }
                    if(item.JobSkillData !== undefined && item.JobSkillData.length > 0){
                        setSelectedJobSkill(item.JobSkillData);
                        setSkillDropDownValue(item.JobSkillData)
                        setSkillDropDownValue(item.JobSkillData)
                    }
                })
            }
        });

       
        
        setIsDataLoaded(true);
    };

    useEffect(() => {
        if (state?.JobId){
            getJobsData(state?.JobId);
            setJobId(state?.JobId);
        }else{
            setIsDataLoaded(true);
        }
    }, [state]);

    const handleJobTypeChange = (event, selectedOptions) => {
        (selectedOptions.length === 0) ? setAutoErrorShow(true) : setAutoErrorShow(false);
        setSelectedJobType(selectedOptions);
    };

    const fetchStaticJobTypes = async () => {
        await WebService({
            endPoint: `CommonUtility/staticjobtypes`,
            method: "GET",
            dispatch
        }).then((res) => res.length > 0 ? setStaticJobTypesData(res.map(item => ({value: item.StaticJobTypeId, text: item.JobType}))) : []);
    };

    const [staticJobScheduleData, setStaticJobScheduleData] = useState([]);
    const [selectedJobSchedule, setSelectedJobSchedule] = useState([]);
    const [autoErrorShowforSchedule, setAutoErrorShowSchedule] = useState(false);

    const handleJobScheduleChange = (event, selectedOptions) => {
        (selectedOptions.length === 0) ? setAutoErrorShowSchedule(true) : setAutoErrorShowSchedule(false);
        setSelectedJobSchedule(selectedOptions);
    };

    const fetchStaticJobSchedules = async () => {
        await WebService({
            endPoint: `CommonUtility/staticjobschedules`,
            method: "GET",
            dispatch
        }).then((res) => res.length > 0 ? setStaticJobScheduleData(res.map(item => ({value: item.StaticJobScheduleId, text: item.JobSchedule}))) : []);
    };


    const [staticJobSupplementalPayData, setStaticJobSupplementalPayData] = useState([]);
    const [selectedJobSupplementalPay, setSelectedJobSupplementalPay] = useState([]);
    const [autoErrorShowforSupplementalPay, setAutoErrorShowSupplementalPay] = useState(false);

    const handleJobSupplementalPayChange = (event, selectedOptions) => {
        (selectedOptions.length === 0) ? setAutoErrorShowSupplementalPay(true) : setAutoErrorShowSupplementalPay(false);
        setSelectedJobSupplementalPay(selectedOptions);
    };

    const fetchStaticJobSupplementalPays = async () => {
        await WebService({
            endPoint: `CommonUtility/staticjobsupplementalpay`,
            method: "GET",
            dispatch
        }).then((res) => res.length > 0 ? setStaticJobSupplementalPayData(res.map(item => ({value: item.StaticSupplementalPayId, text: item.SupplementalPay}))) : []);
    };


    const [staticJobBenefitData, setStaticJobBenefitData] = useState([]);
    const [selectedJobBenefit, setSelectedJobBenefit] = useState([]);
    const [autoErrorShowforBenefit, setAutoErrorShowBenefit] = useState(false);

    const handleJobBenefitChange = (event, selectedOptions) => {
        (selectedOptions.length === 0) ? setAutoErrorShowBenefit(true) : setAutoErrorShowBenefit(false);
        setSelectedJobBenefit(selectedOptions);
    };

    const fetchStaticJobBenefits = async () => {
        await WebService({
            endPoint: `CommonUtility/staticjobbenefits`,
            method: "GET",
            dispatch
        }).then((res) => res.length > 0 ? setStaticJobBenefitData(res.map(item => ({value: item.StaticBenefitId, text: item.Benefit}))) : []);
    };

    const [staticJobSkillData, setStaticJobSkillData] = useState([]);
    const [selectedJobSkill, setSelectedJobSkill] = useState([]);
    const [autoErrorShowforSkill, setAutoErrorShowSkill] = useState(false);
    const [SkillDropDownValue,setSkillDropDownValue]=useState([])


    const handleJobSkillChange = async (event, selectedOptions=[],isTabPressed=false) => {
        if (event.target.value) {
           let result = await CheckExistingSkill(event.target.value)
           if(!isTabPressed){
            selectedOptions.pop()
           }
           if(result)
           {  
                const formattedResult =  { text: result, value: '' }
                setSkillDropDownValue([...SkillDropDownValue, formattedResult]);   
         }
         }  
           else {
            setSkillDropDownValue(selectedOptions);
          }
    };
    
    const CheckExistingSkill = async (newSkill) => {
        try {
          const res = await WebService({
            endPoint: `Recruitment/CheckExistingSkill`,
            body: { skillName: newSkill },
            method: 'POST',
            dispatch
          });
      
          return newSkill;
        } catch (err) {
          WSErrorAlert('', err || 'An error occurred');
          return false;; // Explicitly return undefined
        }
      };

    const fetchStaticJobSkills = async () => {
        await WebService({
            endPoint: `CommonUtility/staticskill`,
            method: "GET",
        }).then((res) => res.length > 0 ? setStaticJobSkillData(res.map(item => ({value: item.SkillId, text: item.SkillName}))) : []);
    };

    const [customerList, setCustomerList] = useState([]);
    const fetchCustomerList = async() => {
        const data = await WebService({
          dispatch,
          endPoint: `CommonUtility/customerdetails`
        });
        const list = data?.filter((r) => r.FullName !== null)?.map((res) => {
          return {
              uniqueId: res.CustomerId,
              heading: res.Name, 
              description: '', 
              avatar: ''
            //   avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
            }
        });
        setCustomerList(list);
      }

    useEffect(() => {
        Promise.all([
            fetchCustomerList(),
            fetchStaticJobTypes(),
            fetchStaticJobSchedules(),
            fetchStaticJobSupplementalPays(),
            fetchStaticJobBenefits(),
            fetchStaticJobSkills()
        ])
    }, []);

    const AddNewSkills = async (SkillArray) => {
        try {
          for (let element of SkillArray) {
            if (!element.value) {
              const res = await WebService({
                endPoint: `Recruitment/addSkill`,
                body: { skillName: element.text }, // Assuming `skillName` is a key in `element`
                method: 'POST',
                dispatch,
              });
      
              element.value = res?.skillId;
            }
          }
      
          return SkillArray; // Return the updated array
        } catch (err) {
         return SkillArray.filter((element) => element.value)
        }
      };
      

    const schema = yup
        .object()
        .shape({
            Title: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            // Location: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();
    
    // Insert Additional Job Details  - START
    const updateJobAttributes = async (JobId, selectedItems, endPointSuffix, StaticId) => {
        if (selectedItems.length > 0) {
            // Delete existing job attributes
            await WebService({
                endPoint: `CommonUtility/${endPointSuffix}?JobId=${JobId}`,
                method: 'DELETE'
            });
    
            // Add selected job attributes
            selectedItems.map(async (item) => {
                await WebService({
                    endPoint: `CommonUtility/${endPointSuffix}`,
                    body: { JobId: JobId, [`${StaticId}`]: item.value },
                    method: 'POST',
                    dispatch,
                });
            });
        }
    }
    
    const updateJobDetails = async (JobId, selectedJobType, selectedJobSchedule, selectedJobSupplementalPay, selectedJobBenefit, selectedJobSkill) => {
        await updateJobAttributes(JobId, selectedJobType, 'jobtypes', 'StaticJobTypeId');
        await updateJobAttributes(JobId, selectedJobSchedule, 'jobschedules', 'StaticJobScheduleId');
        await updateJobAttributes(JobId, selectedJobSupplementalPay, 'jobsupplementalpay', 'StaticSupplementalPayId');
        await updateJobAttributes(JobId, selectedJobBenefit, 'jobbenefits', 'StaticBenefitId');
        const updatedSkillArray= await AddNewSkills(selectedJobSkill)
        await updateJobAttributes(JobId, updatedSkillArray, 'jobskills', 'SkillId');
    }
    // Insert Additional Job Details  - END
    // Form submission code here

    const onSubmit = async (data) => {
       if( data?.SalaryRange==StandardConst.SalaryRange[0].value ) {
             if(Number(data.MaximumSalary) < Number(data.MinimumSalary))
            {
                WSErrorAlert("Error", "Min Salary must be less than from Max Salary");
                return ;
            }
           
        }
        // if(!await handleChangeSalary()) {
        //     return;
        // }
        if(Object.keys(selectedLocation || {})?.length === 0 && SpacificLocationforEmployeeRequired!=0 ){
            WSErrorAlert("Please Select A Job Location");
            return;
        }
        
        data.Location = selectedLocation?.display_name;
        if(SpacificLocationforEmployeeRequired==0 && jobLocationTypeState== 'Fully remote' )
            { 
                data.Location=null
            }

        if(jobDescriptionValue !== ''){
            data.Description = jobDescriptionValue;
        }
        if (Array.isArray(data.CustomerId)) {
            data.CustomerId = data.CustomerId[0]?.uniqueId ?? null;
        }else{
            data.CustomerId = data.CustomerId?.uniqueId ?? null;
        }
        data.PlannedStartDate = format(new Date(data.PlannedStartDate), "yyyy-MM-dd");
        if(EmployeeId) data.EmployeeId = EmployeeId;
        if(UserId) data.UserId = UserId;
        if (data.JobId === undefined) {
            const JobId = await WebService({
                endPoint: "CommonUtility/jobs",
                body: data,
                dispatch,
            })
        
            data.JobId = JobId;
            WSSuccessAlert('','Job Added')


        } else {
            await WebService({
                endPoint: `CommonUtility/jobs?JobId=${data.JobId}`,
                method: "PUT",
                body: data,
                dispatch,
            })
            WSSuccessAlert('','Job Updated')
          
        }

        await updateJobDetails(data.JobId, selectedJobType, selectedJobSchedule, selectedJobSupplementalPay, selectedJobBenefit, SkillDropDownValue);
     
        navigate('/MyJobList');
    };

    const [jobDescriptionValue, setJobDescriptionValue] = useState('');


    const [currencySymbol, setCurrencySymbol] = useState(null);

    const changeCurrency = async (CurrencyId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/staticcurrency?CurrencyId=${CurrencyId}`,
            method: 'GET',
        }).then((res) => res.length > 0 ? setCurrencySymbol(res[0].Symbol) : null);
    };


    // useEffect(() => {
    //     if(salaryRange === 'Range'){
    //         handleChangeSalary();
    //     }
    // },[minSalary, maxSalary]);
    // This state set for data loading Show a Blank Div while data is being fetched, it is most effective in Edit time.
    if (!isDataLoaded) {
        return <div></div>;
    }


    return (
      <Container
        fluid
        style={{
          padding: "0px",
          borderRadius: "5px ",
          margin: "10px",
          backgroundColor: "#fff",
          width: "90%",
        }}
      >
        <Topbar bData={bData} HeadingText={"Post A Job"}></Topbar>

        <div className="row">
          <div className="col-12 col-md-8">
            <Form
              defaultValues={jobData}
              onSubmit={onSubmit}
              validationSchema={schema}
            >
              <div className="card shadow-none footer-widget">
                {/* <Alert severity="info" sx={{ width: "95%" }}>Basic Information</Alert> */}
                <div className="card-body">
                  <div className="row mt-1">
                    {/* Basic Information - Section - START */}
                    <Alert
                      className="my-2"
                      severity="info"
                      sx={{ width: "99%" }}
                    >
                      Basic Information
                    </Alert>
                    <div className="col-md-12">
                      <FormInputText
                        label="Job Title"
                        name="Title"
                        placeholder="Title"
                        type="text"
                        isRequired="true"
                      />
                    </div>

                    <div className="col-md-12 mt-1">
                      <div className="row">
                        <div
                          className="col-md-5 d-flex align-items-center justify-content-between"
                          style={{ fontSize: "14px" }}
                        >
                          <label>Job Type</label>
                        </div>
                        <div
                          className="col-md-7"
                          style={{ paddingLeft: "0px" }}
                        >
                          <Autocomplete
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "1px 8px",
                              },
                            }}
                            multiple
                            id="grouped-demo"
                            options={staticJobTypesData}
                            getOptionLabel={(option) => option?.text}
                            defaultValue={selectedJobType.map(
                              (selectedData) =>
                                staticJobTypesData[
                                  staticJobTypesData.findIndex(
                                    (pitem) =>
                                      pitem.value === selectedData.value
                                  )
                                ]
                            )}
                            onChange={handleJobTypeChange}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Choose Job Type"
                                error={autoErrorShow} // Set error state of TextField
                                helperText={
                                  autoErrorShow && "Select atleast one Job Type"
                                } // Display error message if error is true
                                sx={{
                                  "& .MuiOutlinedInput-input": {
                                    border: "none",
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div
                          className="col-md-5 d-flex align-items-center justify-content-between"
                          style={{ fontSize: "14px" }}
                        >
                          <label>Shift Type</label>
                        </div>
                        <div
                          className="col-md-7"
                          style={{ paddingLeft: "0px" }}
                        >
                          <Autocomplete
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "1px 8px",
                              },
                            }}
                            multiple
                            id="grouped-demo"
                            options={staticJobScheduleData}
                            // groupBy={(option) => option.ParentId === null ? option.text : ""}
                            getOptionLabel={(option) => option?.text}
                            defaultValue={selectedJobSchedule.map(
                              (selectedData) =>
                                staticJobScheduleData[
                                  staticJobScheduleData.findIndex(
                                    (pitem) =>
                                      pitem.value === selectedData.value
                                  )
                                ]
                            )}
                            onChange={handleJobScheduleChange}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Choose Job Schedule"
                                placeholder="Choose Job Schedule"
                                error={autoErrorShowforSchedule} // Set error state of TextField
                                helperText={
                                  autoErrorShowforSchedule &&
                                  "Select atleast one Job Schedule"
                                } // Display error message if error is true
                                sx={{
                                  "& .MuiOutlinedInput-input": {
                                    border: "none",
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-12 mt-1">
                                        <FormInputDropdown
                                            label="Language of job post"
                                            name="Language"
                                            ddOpt={StandardConst.Language}
                                        />
                                    </div> */}

                    {/* Basic Information - Section - END */}

                    {/* Job Location - Section - START */}
                    <Alert
                      className="my-2"
                      severity="info"
                      sx={{ width: "99%" }}
                    >
                      Job Location
                    </Alert>
                    <div className="col-md-12 mt-1">
                      <FormInputDropdown
                        label="Country where job post is shown"
                        name="CountryId"
                        ddOpt={StandardConst.Countries.map((m) => ({
                          value: m.CountryId,
                          text: m.Country,
                        }))}
                      />
                    </div>
                    <div className="col-md-12 mt-1">
                      <FormInputDropdown
                        label="Job Location Type"
                        name="JobLocationType"
                        ddOpt={StandardConst.JobLocationType}
                        setValue={(val) => setJobLocationTypeState(val)}
                      />
                    </div>

                    {jobLocationTypeState === "Fully remote" && (
                      <div className="col-md-12 mt-1">
                        <FormInputDropdown
                          label="Is employee required to reside in a specific location?"
                          name="SpacificLocationforEmployeeRequired"
                          ddOpt={StandardConst.YesOrNo}
                          setValue={(val) =>
                            setSpacificLocationforEmployeeRequired(val)
                          }
                        />
                      </div>
                    )}

                    {jobLocationTypeState === "On the road" && (
                      <div className="col-md-12 mt-2">
                        <FormInputText
                          label="What is the operating area of this job?"
                          name="OperatingArea"
                          // isRequired="true"
                        />
                      </div>
                    )}

                    {!(
                      SpacificLocationforEmployeeRequired == 0 &&
                      jobLocationTypeState === "Fully remote"
                    ) && (
                      <div className="col-md-12 mt-2">
                        <div className="row">
                          <div
                            className="col-md-5 d-flex align-items-center justify-content-between"
                            style={{ fontSize: "14px" }}
                          >
                            <label>Job Location</label>
                          </div>
                          <div
                            className="col-md-7"
                            style={{ paddingLeft: "0px" }}
                          >
                            {LocationDefaultValue && (
                              <Autocomplete
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    padding: "1px 8px",
                                  },
                                }}
                                // multiple
                                id="grouped-demo"
                                options={apiLocationData}
                                getOptionLabel={(option) =>
                                  option?.display_name || ""
                                }
                                defaultValue={LocationDefaultValue || " "}
                                onInputChange={fetchLocation}
                                loading={LocationLoading}
                                onChange={selectedJobLocation}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // label="Choose Job Supplemental Pay"
                                    placeholder="Input city name..."
                                    error={locationErrorShow} // Set error state of TextField
                                    helperText={
                                      locationErrorShow &&
                                      "Select location in dropdown"
                                    } // Display error message if error is true
                                    sx={{
                                      "& .MuiOutlinedInput-input": {
                                        border: "none",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                )}
                              />
                            )}
                          </div>
                        </div>

                        {/* <FormInputText
                                            label="Job Location"
                                            name="Location"
                                            type="text"
                                            isRequired="true"
                                            setValue={(val) => fetchLocation(val)}
                                        />
                                        {apiLocationData.length > 0 && (
                                            <ul>
                                                {cities.map((city, index) => (
                                                <li key={index} >
                                                    {city.display_name}
                                                </li>
                                                ))}
                                            </ul>
                                        )} */}
                      </div>
                    )}

                    {/* Job Location - Section - START */}

                    {/* Pay and Benefits - Section - START */}
                    <Alert
                      className="my-2"
                      severity="info"
                      sx={{ width: "99%" }}
                    >
                      Pay and Benefits
                    </Alert>
                    <div className="col-md-12 mt-1">
                      <FormInputDropdown
                        label="Currency"
                        name="CurrencyId"
                        ddOpt={StandardConst.Currency}
                        setValue={(val) => changeCurrency(val)}
                      />
                    </div>

                    <div className="col-md-12 mt-2">
                      <FormInputDropdown
                        label="Salary Range"
                        name="SalaryRange"
                        ddOpt={StandardConst.SalaryRange}
                        setValue={(val) => setSalaryRange(val)}
                      />
                    </div>

                    <div className="col-md-12">
                      <div className="row">
                        <div
                          className="col-md-5 d-flex justify-content-start align-items-center"
                          style={{ fontSize: "14px" }}
                        >
                          <label className="text-center">Salary</label>
                        </div>
                        <div
                          className="col-md-7 mt-2"
                          style={{ paddingLeft: "1.5px" }}
                        >
                          <div
                            className={`d-flex flex-column  justify-content-end align-items-end`}
                          >
                            <span className="d-flex flex-row  justify-content-end">
                              {(salaryRange === "Starting Salary" ||
                                salaryRange === "Range") && (
                                <div
                                  className={
                                    salaryRange === "Starting Salary"
                                      ? "col-md-12"
                                      : "col-md-5"
                                  }
                                >
                                  <div className="d-flex justify-content-start align-items-center">
                                    <span>{currencySymbol}</span> &nbsp;
                                    <FormInputText
                                      placeholder={
                                        salaryRange === "Starting Salary"
                                          ? "Minimum Salary"
                                          : "Min"
                                      }
                                      name="MinimumSalary"
                                      type="number"
                                      isRequired="true"
                                      setValue={(val) => setMinSalary(val)}
                                    />
                                  </div>
                                </div>
                              )}
                              {salaryRange === "Range" && (
                                <div
                                  className="col-md-2 d-flex justify-content-center align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  <label>To</label>
                                </div>
                              )}
                              {(salaryRange === "Maximum Salary" ||
                                salaryRange === "Range") && (
                                <div
                                  className={
                                    salaryRange === "Maximum Salary"
                                      ? "col-md-12"
                                      : "col-md-5"
                                  }
                                >
                                  <div className="d-flex justify-content-start align-items-center">
                                    <span>{currencySymbol}</span> &nbsp;
                                    <FormInputText
                                      placeholder={
                                        salaryRange === "Maximum Salary"
                                          ? "Maximum Salary"
                                          : "Max"
                                      }
                                      name="MaximumSalary"
                                      type="number"
                                      isRequired="true"
                                      setValue={(val) => setMaxSalary(val)}
                                    />
                                  </div>
                                </div>
                              )}

                              {salaryRange === "Exact Salary" && (
                                <div className="col-md-12">
                                  <div className="d-flex justify-content-start align-items-center">
                                    <span>{currencySymbol}</span> &nbsp;
                                    <FormInputText
                                      placeholder="Exact Salary"
                                      name="ExactSalary"
                                      type="number"
                                      isRequired="true"
                                    />
                                  </div>
                                </div>
                              )}
                            </span>
                            <span className="col-md-4 d-flex justify-content-end align-items-center">
                              <div className="col-md-12">
                                <FormInputDropdown
                                  name="Rate"
                                  ddOpt={StandardConst.JobsRate}
                                />
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div
                          className="col-md-5 d-flex align-items-center justify-content-between"
                          style={{ fontSize: "14px" }}
                        >
                          <label>Job supplemental pay</label>
                        </div>
                        <div
                          className="col-md-7"
                          style={{ paddingLeft: "0px" }}
                        >
                          <Autocomplete
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "1px 8px",
                              },
                            }}
                            multiple
                            id="grouped-demo"
                            options={staticJobSupplementalPayData}
                            // groupBy={(option) => option.ParentId === null ? option.text : ""}
                            getOptionLabel={(option) => option?.text}
                            defaultValue={selectedJobSupplementalPay.map(
                              (selectedData) =>
                                staticJobSupplementalPayData[
                                  staticJobSupplementalPayData.findIndex(
                                    (pitem) =>
                                      pitem.value === selectedData.value
                                  )
                                ]
                            )}
                            onChange={handleJobSupplementalPayChange}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Choose Job Supplemental Pay"
                                placeholder="Choose Job Supplemental Pay"
                                error={autoErrorShowforSupplementalPay} // Set error state of TextField
                                helperText={
                                  autoErrorShowforSupplementalPay &&
                                  "Select atleast one Job Supplemental Pay"
                                } // Display error message if error is true
                                sx={{
                                  "& .MuiOutlinedInput-input": {
                                    border: "none",
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div
                          className="col-md-5 d-flex align-items-center justify-content-between"
                          style={{ fontSize: "14px" }}
                        >
                          <label>Job Benefits</label>
                        </div>
                        <div
                          className="col-md-7"
                          style={{ paddingLeft: "0px" }}
                        >
                          <Autocomplete
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "1px 8px",
                              },
                            }}
                            multiple
                            id="grouped-demo"
                            options={staticJobBenefitData}
                            // groupBy={(option) => option.ParentId === null ? option.text : ""}
                            getOptionLabel={(option) => option?.text}
                            defaultValue={selectedJobBenefit.map(
                              (selectedData) =>
                                staticJobBenefitData[
                                  staticJobBenefitData.findIndex(
                                    (pitem) =>
                                      pitem.value === selectedData.value
                                  )
                                ]
                            )}
                            onChange={handleJobBenefitChange}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Choose Job Benefit"
                                placeholder="Choose Job Benefit"
                                error={autoErrorShowforBenefit} // Set error state of TextField
                                helperText={
                                  autoErrorShowforBenefit &&
                                  "Select atleast one Job Benefit"
                                } // Display error message if error is true
                                sx={{
                                  "& .MuiOutlinedInput-input": {
                                    border: "none",
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Pay and Benefits - Section - START */}

                    {/* Skills - Section - START */}
                    <Alert
                      className="my-2"
                      severity="info"
                      sx={{ width: "99%" }}
                    >
                      Skills
                    </Alert>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div
                          className="col-md-5 d-flex align-items-center justify-content-between"
                          style={{ fontSize: "14px" }}
                        >
                          <label>Job Required Skill</label>
                        </div>
                        <div
                          className="col-md-7"
                          style={{ paddingLeft: "0px" }}
                        >
                          <Autocomplete
                            freeSolo={true}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "1px 8px",
                              },
                            }}
                            multiple
                            value={SkillDropDownValue}
                            id="grouped-demo"
                            options={staticJobSkillData}
                            getOptionLabel={(option) => option?.text}
                            defaultValue={selectedJobSkill.map((selectedData) =>
                              staticJobSkillData.find(
                                (pitem) => pitem.value === selectedData.value
                              )
                            )}
                            onChange={handleJobSkillChange}
                            onKeyDown={(event) => {
                                if(event.key === 'Tab'){
                                    console.log('pressed ')
                                    handleJobSkillChange(event,SkillDropDownValue,true)
                                }
                              else if  (event.key === "Enter") {
                                event.preventDefault(); // Prevent adding on Enter
                                event.preventDefault(); // Explicitly prevent default behavior
                                event.stopPropagation(); // Stop event bubbling
                                console.log('Enter key pressed, but action prevented');
                              }
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Choose Job Required Skill"
                                error={autoErrorShowforSkill}
                                helperText={
                                  autoErrorShowforSkill &&
                                  "Select at least one Job Required Skill"
                                }
                                sx={{
                                  "& .MuiOutlinedInput-input": {
                                    border: "none",
                                  },
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    onKeyDown: (e) => {
                                          if (e.key === 'Enter') {
                                            e.stopPropagation();
                                          }
                                    },
                                  }}
                               
                              />
                            )}
                          />
                          <span className="text-muted text-sm">
                            Note: use tab button to save skills
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Skills - Section - END */}

                    {/* Job Description - Section - START */}
                    <Alert
                      className="my-2"
                      severity="info"
                      sx={{ width: "99%" }}
                    >
                      Job Description
                    </Alert>
                    <div className="col-md-12 mt-2">
                      <div className="row">
                        <div
                          className="col-md-5 d-flex align-items-center justify-content-between"
                          style={{ fontSize: "14px" }}
                        >
                          <label>Description</label>
                        </div>
                        <div
                          className="col-md-7"
                          style={{ paddingLeft: "0px" }}
                        >
                          <RichTextBox
                            value={jobDescriptionValue}
                            setValue={setJobDescriptionValue}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Job Description - Section - END */}

                    {/* Experiance - Section - START */}
                    <Alert
                      className="my-2"
                      severity="info"
                      sx={{ width: "99%" }}
                    >
                      Experiance
                    </Alert>
                    <div className="col-md-12">
                      <div className="row">
                        <div
                          className="col-md-5 d-flex align-items-center justify-content-between"
                          style={{ fontSize: "14px" }}
                        >
                          <label>Required Experience Range</label>
                        </div>

                        <div
                          className="col-md-7 mt-2"
                          style={{ paddingLeft: "1.5px" }}
                        >
                          <div className="row">
                            <div className="col-md-4">
                              <FormInputText
                                placeholder="Minimum Experiance"
                                name="MinimumExperiance"
                                type="number"
                              />
                            </div>
                            <div
                              className="col-md-2 d-flex justify-content-center align-items-center"
                              style={{ fontSize: "14px" }}
                            >
                              <label>To</label>
                            </div>
                            <div className="col-md-4">
                              <FormInputText
                                placeholder="Maximum Experiance"
                                name="MaximumExperiance"
                                type="number"
                              />
                            </div>
                            <div
                              className="col-md-2 d-flex justify-content-center align-items-center"
                              style={{ fontSize: "14px" }}
                            >
                              <label>Months</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Experiance - Section - END */}

                    {/* No. of vacancies - Section - START */}
                    <Alert
                      className="my-2"
                      severity="info"
                      sx={{ width: "99%" }}
                    >
                      No. of vacancies
                    </Alert>
                    <div className="col-md-12 mt-1">
                      <FormInputDropdown
                        label="Number of positions"
                        name="NumberOfPeopleToHire"
                        ddOpt={Array.from({ length: 10 }, (_, index) => ({
                          text: index + 1,
                          value: index + 1,
                        }))}
                      />
                    </div>
                    {/* No. of vacancies - Section - END */}

                    {/* Is this Job Post for a customer ? - Section - START */}
                    <Alert
                      className="my-2"
                      severity="info"
                      sx={{ width: "99%" }}
                    >
                      Is this Job Post for a customer ?
                    </Alert>
                    <div className="col-md-12 mt-1">
                      <FormAutoCompleteDropdownCard
                        name="CustomerId"
                        data={chain(customerList ?? [])}
                        label="Customer"
                        optionText="heading"
                        setValue={(val) =>
                          setApplicantIsVisibaleToCustomer(true)
                        }
                      />
                    </div>
                    {applicantIsVisibaleToCustomer && (
                      <div className="col-md-12 mt-1">
                        <FormInputDropdown
                          label="Applicant Is VisibleTo Customer"
                          name="ApplicantIsVisibleToCustomer"
                          ddOpt={StandardConst.YesOrNo}
                        />
                      </div>
                    )}
                    {/* Is this Job Post for a customer ? - Section - START */}

                    {/* Other Information - Section - START */}
                    <Alert
                      className="my-2"
                      severity="info"
                      sx={{ width: "99%" }}
                    >
                      Other Information
                    </Alert>
                    <div className="col-md-12 mt-2">
                      <FormInputText
                        label="Planned Start Date"
                        name="PlannedStartDate"
                        type="date"
                        value={new Date()}
                        // isRequired="true"
                      />
                    </div>

                    <div className="col-md-12 mt-1">
                      <FormInputDropdown
                        label="Hiring timeline of this job"
                        name="HiringTimeline"
                        ddOpt={StandardConst.HiringTimelineForJob}
                      />
                    </div>

                    <div className="col-md-12 mt-1">
                      <FormInputDropdown
                        label="Status"
                        name="StatusId"
                        ddOpt={StandardConst.JobStatusDropdown}
                      />
                    </div>
                    {/* Other Information - Section - START */}

                    <div>
                      <Button
                        id="btnSubmit"
                        className="px-4 float-end mt-4 mb-1"
                        variant="outline-primary"
                        type="submit"
                      >
                        {" "}
                        {(jobData?.JobId || 0) === 0
                          ? "Submit"
                          : "Save Changes"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    );
};

export default AddEditJobPosting;
