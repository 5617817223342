import React, { useState } from "react";
import {
  Container,
  Card,
  Chip,
  Autocomplete,
  TextField,
  Box,
  Tabs,
  Tab,
  useTheme,
  CircularProgress,
  Alert,
  Checkbox,
  LinearProgress,
  TablePagination,
} from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { WebService } from "../../Services/WebService";
import { useEffect } from "react";
import { StandardConst } from "../../Services/StandardConst";
import TableComponent from "../../Services/TableComponent";
import AppbarComponent from "../../Services/AppbarComponent";
import {
    CheckBox,
    CheckBoxOutlineBlank,
  Code,
  Error,
  Preview,
  Send,
  TaskAlt,
  Warning,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ActionPermission } from "../PageInfo";
import { WSSuccessAlert } from "../../Services/WSAlert";
import Col from "react-bootstrap/esm/Col";
import { Form, FormAutoCompleteDropdownCard } from "../Form";

import '../../asset/Form.css'
import { BasicInfo } from "../../Services/authSlice";
const SendMailToUsers = () => {
  const [ReceiverData, setReceiverData] = useState([]);
  const [EmailTemplateList, setEmailTemplateList] = useState([]);
  const [progressLoading, setprogressLoading] = useState(false);
  // this state handles the preview of the email template and its html  code
  const [value, setValue] = React.useState(1);
  const [type, setType] = useState("employee"); // Default type
  const [page, setPage] = useState(0); // Current page
  const [limit, setLimit] = useState(10); // Items per page
  const [TotalItems, setTotalItems] = useState(1); // Total 
  const [bData] = useState([
    {
      title: "Send Mail",
      hrefLink: "/SendMailToUser",
    },
    {
      title: "",
      hrefLink: "#",
    },
  ]);

    const [permission, SetPermission] = useState({});
    const permissionList = useSelector((s) => s.auth.PermissionList??[]);
    useEffect(() => {
      SetPermission({
        sendMail: ActionPermission("Visitor Type - Add"),
      });
    }, [permissionList]);

  const [EmailhtmlContent, setEmailHtmlContent] = useState({
    subject: "",
    body: "",
  });

  const BasicInfo = useSelector((s)=>s.auth.BasicInfo ?? {})
  const MasterPageName = "Send Email";
  const [Recepient,setRecepient]=useState(StandardConst.SendMailDropdown)
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});

    const getRecepientDropdownValues = async () => {
      WebService({
        endPoint: "Roles/getSystemRoles",
      })
        .then((systemRoles) => {
          const roles = Array.isArray(systemRoles)
            ? systemRoles.map((role) => ({
                text: `All ${role.RoleName}`,
                value: Number(role.RoleId),
                heading:`All ${role.RoleName}`
              }))
            : [];
            setRecepient((prev) => {
                const newRecepients = [...prev,...roles];
                
                if (Roles.includes(StandardConst.SystemRole.SuperAdmin)) {
                  newRecepients.push({ value: 'subscribers', text: 'People who have subscribed',heading:'People who have subscribed' });
                }
                
                return newRecepients;
              }); 
        })
        .catch((error) => {
          console.error("Error fetching system roles:", error);
        });
    };
 
  useEffect(()=>{
    getRecepientDropdownValues()
  },[])
   const getEMailTempalate = async () => {
      try {
        const groups = await WebService({
          endPoint: `CommonUtility/fetchRecordsByStatusListWithoutCompanyId/staticemailtemplate?CompanyId=${BasicInfo.CompanyId}`,
        })
        setEmailTemplateList(groups);
      } catch (error) {
        console.error("Error fetching mail groups:", error);
      }
    };
  function replacePlaceholders(html, values) {
    // Convert keys in values to lowercase for case-insensitive matching
    const lowerCaseValues = Object.keys(values).reduce((acc, key) => {
      acc[key.toLowerCase()] = values[key];
      return acc;
    }, {});

    // Replace placeholders in a case-insensitive manner
    return html.replace(/@(\w+)/gi, (match, key) => {
      const lowerCaseKey = key.toLowerCase();
      return lowerCaseKey in lowerCaseValues
        ? lowerCaseValues[lowerCaseKey]
        : "";
    });
  }

  useEffect(() => {
    getEMailTempalate();
  }, []);
  const handleSubmit = async () => {
    setprogressLoading(true);
    let values = {};
    for (let i = 0; i < ReceiverData.length; i++) {
       
      const Receiver = ReceiverData[i];
      if(!Receiver.Checked){
        continue;
        }
      // Get all key-value pairs as an array
      const entries = Object.entries(Receiver);
         values.EmailSubject=replacePlaceholders(EmailhtmlContent.subject,Receiver);
         values.EmailMailBody=replacePlaceholders(EmailhtmlContent.body,Receiver);
      // Remove the first key-value pair and store it in a variable
      const [firstKey, firstValue] = entries.shift();
      // Log the removed value
      values.ReceiverMail = Receiver.Email;
      // Update the table status to 'sending' directly using index
      setReceiverData((prevTableData) => {
        const updatedTableData = [...prevTableData];
        updatedTableData[i].status = "sending";
        return updatedTableData;
      });

      try {
        // Send the email
        await WebService({
          endPoint: `Document/sendEmailToUsers`,
          method: "post",
          body: values,
        });

        // Update the table status to 'sent' directly using index
        setReceiverData((prevTableData) => {
          const updatedTableData = [...prevTableData];
          updatedTableData[i].status = "sent";
          return updatedTableData;
        });
      } catch (error) {
        // In case of failure, update the table status to 'failed'
        setReceiverData((prevTableData) => {
          const updatedTableData = [...prevTableData];
          updatedTableData[i].status = "failed";
          return updatedTableData;
        });
      }
    }
    WSSuccessAlert("", "Email Sending process completed");
    setprogressLoading(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const handleCheck = (data) => {
    setReceiverData((prev) => {
      const updatedEmployees = [...prev]; // Create a copy of the previous state
      updatedEmployees[data.index] = { 
        ...updatedEmployees[data.index], 
        Checked: !updatedEmployees[data.index]?.Checked ?? true // Toggle or set default to true
      };
      return updatedEmployees; // Return the updated array
    });
  };
  const excludedKeys=["EmployeeId",'Department','Guid','EventId','Checked' ,'CreatedBy','OfficeLocationId','ScheduleCheckInTime','CreatedOn','InvitedBy','ContactPerson','VisitorTypeId','ParentVisitorId',"UserId","CustomerId","UserStatusId","UserDesignationId","vartualId","StatusId","Code","EmployeeCode",'Password','CompanyId','TimeStamp','IsIdCardActive','']
  const columns = [
    {
      Text: "Select Recipients",
      render: (dr) => {
        return (
          <span> 
            {dr.Checked}
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={dr?.Checked || false}
              onChange={() => handleCheck(dr)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </span>
        );
      },
    },
    ...Object.keys(ReceiverData[0] || {})
    .filter((key) => !excludedKeys.includes(key))
    .map((key) => ({
      Text: key.charAt(0) + key.slice(1), // Capitalize the column header
      Value: key,
    })),
     {
          Text:'Sent Status',
          render:(dr)=>(
            <span className="d-flex col-auto">
                  {dr.status === 'sent' ? (
                    <>sent {"  "}  <TaskAlt color="success" fontSize="small" /></>
                  ) : dr.status === 'pending' ? (
                    <>pending {"  "} <Warning color="warning" fontSize="small" /></>
                  ) : dr.status === 'failed' ? (
                    <>failed {"  "}  <Error color="error" fontSize="small" /></>
                  ) : dr.status === 'sending' ? (
                    <>sending {"  "}  <CircularProgress size={10} /></>
                  ) : (
                    <>pending {"  "} <Warning color="warning" fontSize="small" /></>
                  )}
                </span>
          )
    
        }
  ];



  const fetchTableData = () => {
    WebService({
        endPoint:`User/getReceivers?page=${page}&limit=${limit}&type=${type}`
      }).then(r =>{ 
        console.log(r)
        setReceiverData(r.data);
        setTotalItems(r.pagination.totalItems)
        setPage(r.pagination.currentPage)
     });
  };

  useEffect(()=>{
    fetchTableData()
},[type,limit,page])
  

  return (
    <>
     <Container fluid className="base-container ">
      {/* Header Section */}

      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h5 className="ms-4 mt-2">{MasterPageName}</h5>
          <div className="ms-4">
            <BreadcrumbsComponent bData={bData} />
          </div>
        </div>
      </div>
      <div className="dropdowns d-flex flex-row mx-1 py-1 justify-content-between bg-primary">
            <span className="col-md-4 ms-4"> 
                <Autocomplete
                        options={Recepient}
                        
                        getOptionLabel={(option) => option?.text || ``}
                        // defaultValue={EmailTemplateList[0]}
                        onChange={(event, value) =>
                            setType(value.value)
                        }
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Choose Receivers"
                            variant="standard"
                            sx={{padding:'1px',borderRadius:'2px'}}
                        />
                       
                        )}
                        sx={{  backgroundColor: '#3184fd',border:'none', padding:'1px',margin:'1px'
                        }}
                    />
            </span>
            
     </div>
      <Card className="shadow-lg  px-1 w-100 mx-auto ">
    

          <div className="">
            <TableComponent
              isSearchRequired={true}
              data={ReceiverData}
              columns={columns}
              bData={false}
            />
             <TablePagination
             
                component="div"
                count={TotalItems}
                page={page}
                onPageChange={(e, v) => {
                      setPage(v); 
                  }} 
                rowsPerPage={limit}
                onRowsPerPageChange={(e,v)=>{
                    setLimit(parseInt(e.target.value, 10));
                    setPage(0);
                }}
                />
          </div>
      </Card>
    </Container>
    <Container fluid className="base-container my-2 pb-4">  
        {/* section for email template edit and view at same placeholder */}
      
     <Alert severity="info">Email To Be Send </Alert>
      <card className='pb-4 mb-3'>

      
        
          <div className="my-4 px-4">
            {/* top-bar */}
            <div className="col-12 py-2">
                      <Alert severity="warning" className="w-100">
                        Allowed keyword in email -{" "}
                        {columns
                          ?.map((placeholder) => `@${placeholder.Text}`)
                          .join(", ")}
                      </Alert>
                    </div>
            <div className="d-flex flex-lg-row flex-md-row  flex-column justify-content-between gap-1">
              <div className="col-md-6 ">
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Email Subject"
                  value={EmailhtmlContent.subject}
                  onChange={(e) =>
                    setEmailHtmlContent((prev) => ({
                      ...prev,
                      subject: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col-md-5">
                <Autocomplete
                  options={EmailTemplateList}
                  getOptionLabel={(option) => option?.Title || ``}
                  // defaultValue={EmailTemplateList[0]}
                  onChange={(event, newValue) =>
                    setEmailHtmlContent((prev) => ({
                      ...prev,
                      subject: newValue?.Subject || "",
                      body: newValue?.Body || "",
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Use a mail template to draft email"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start my-2">
              <Box sx={{ width: "100%" }}>
                <Box>
                  <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                    {/* Tabs Section */}
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      className="col-12 col-lg-5 "
                      scrollButtons="auto"
                      sx={{
                        '& .MuiTabs-flexContainer': {
                          height: '55px',
                        },
                      }}
                      style={{}}
                    >
                      <Tab
                        label="HTML Code"
                        icon={
                          <Code className="mb-0" sx={{ marginBottom: 0 ,paddingBottom:0}} />
                        }
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          
                          gap: "5px",
                          alignItems: "center",
                        }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Email Preview"
                        icon={
                          <Preview className="mb-0" sx={{ marginBottom: 0 }} />
                        }
                        sx={{
                          display: "flex",
                          gap: "5px",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                    {/* Alert Section */}
                    
                  </div>
                </Box>

                {/* Tab Panels */}
                <CustomTabPanel value={value} index={0}>
                  <div  className="">
                    <textarea

                          rows={20}              
                       multiline={true}
                      value={EmailhtmlContent.body}
                      placeholder={` Write the email content in the form of HTML. Use @keyword to substitute value from your csv file.
                      <!DOCTYPE html>
                      <html lang="en">
                      <head>
                          <title>Placeholder Example</title>
                      </head>
                      <body>
                          <h2>Sample Email Example</h2>
                          <p>Use placeholders like @userName to represent dynamic data in the Email. These placeholders should be the same as your csv file heading</p>
                      </body>
                      </html>`}
                      onChange={(e) =>
                        setEmailHtmlContent((prev) => ({
                          ...prev,
                          body: e.target.value,
                        }))
                      }
                     
                      
                    />
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <div className="border border-1 mt-2">
                    {EmailhtmlContent.body ? (
                      <iframe
                        id="html-preview"
                        title="html-preview"
                        className="w-100"
                        height="600px"
                        srcDoc={EmailhtmlContent.body}
                        frameBorder="0"
                      />
                    ) : (
                      <div
                        class="container d-flex justify-content-center align-items-center"
                        style={{ minHeight: "300px", padding: "20px" }}
                      >
                        <div class="text-center p-4 ">
                          <p>
                            No HTML content available. Please ensure you include
                            placeholders such as @name, @email, etc., in your
                            email template.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        
         
          <div className=" col-md-12 w-100 d-flex flex-column align-items-end  justify-content-end gap-2 mb-2 px-4">
            <button
              className="btn btn-primary"
              type="button"
            
              onClick={handleSubmit}
              id="inputGroupFileAddon04"
              disabled={progressLoading && permission.sendMail}
            >
              {!progressLoading ? (
                <>
                  <Send /> Send EMail To Recipients
                </>
              ) : (
                <>Sending ...</>
              )}
            </button>

           
          </div>
     
        </card>
     </Container>
    </>
   
  );
};

export default SendMailToUsers;

const CustomTabPanel = React.memo((props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <span sx={{}}>{children}</span>}
    </div>
  );
});
