import React, { useEffect, useRef, useState } from "react";
import { StandardConst } from "../../Services/StandardConst";
import TableComponent, {
  NoRecordTemplate,
} from "../../Services/TableComponent";
import { useDispatch, useSelector } from "react-redux";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import { WebService } from "../../Services/WebService";
import { ActionPermission, PageInfo } from "../PageInfo";
import Button from "react-bootstrap/Button";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from "../../Services/ActionButton";
import {Chip } from "@mui/material";
import {  useLocation, useNavigate } from "react-router-dom";
import AddEditDocument from "./AddEditDocument";
import StaticListComponent from "../../Services/StaticListComponent";
import { WSSuccessAlert } from "../../Services/WSAlert";

const ManageDocument = () => {
  const refSnackbar = useRef();
  const ref = useRef();
  const MasterPageName = "Send Document To Multiple Users";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = " Document Template";
  const dispatch = useDispatch();
  PageInfo({ pageTitle: "Manage Events" });
  const addEditModalRef = useRef();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [DocumentTemplates, setDocumentTemplates] = useState([]);
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const Roles = useSelector((s) => s.auth.AssignRole ?? {});

  useEffect(() => {
    if (state?.openModal) {
      fnEdit(0);
    }
  }, [state]);

  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
  const [permission, SetPermission] = useState({});
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Document Template - Add"),
      ManageEdit: ActionPermission("Document Template - Edit"),
      ManageDelete: ActionPermission("Document Template - Delete"),
      ManageSend: ActionPermission("Document Template - Send"),
      ManageHistory: ActionPermission("Document Template - History"),
      ManageView: ActionPermission("Document Template - View"),
    });
  }, [permissionList]);
  const [bData] = React.useState([
    {
      title: "Document Template List",
      hrefLink: "/ManageDocument",
    },
    {
      title: "",
      hrefLink: "",
    },
  ]);
  const fetchDocumentTempalete = async () => {
    const data = await WebService({
      endPoint: `Document/GetDocumentTempaletes/`,
      method: "get",
      dispatch,
    });
    setDocumentTemplates(data.result);
    dispatch(ConfigurationflowStatusUpdate());
  };

  useEffect(() => {
    fetchDocumentTempalete();
  }, []);
  function beautifyLocalTime(utcTimestamp) {
    // Convert the UTC timestamp string to a Date object
    const utcDate = new Date(utcTimestamp);
  
    // Use Intl.DateTimeFormat to format the date in the local time zone
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
  
    // Format the date
    return new Intl.DateTimeFormat(undefined, options).format(utcDate);
  }
  const onDelete = async (dr) => {
    refSnackbar.current.setOpenSnackBar();
    await WebService({
        endPoint: `CommonUtility/DocumentTemplates?DocumentTemplateId=${dr.DocumentTemplateId   }`,
        method: "delete",
        dispatch,
    })
    WSSuccessAlert("",` Document '${dr.DocumentTemplateName}' Deleted Sucessfully`)
    await fetchDocumentTempalete();
  };

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

  const SendDocument = (dr) => {
    if (dr.RecieverType == StandardConst.ReceieverTypeOptions[0].value) {
      navigate(`/SendDocumentInternalUser/${dr.DocumentTemplateId}`);
    } else {
      navigate(`/SendDocumentExternalUser/${dr.DocumentTemplateId}`);
    }
  };

  const columns = [
    {
      Text: "List of document template",
      Value: "DocumentTemplateName",
      render: (dr) => (
        <>
        {dr.DocumentTemplateName} {' '}
        <Chip
         label={`${dr.RecieverType} Users`}  
         size="small"
         color={dr.RecieverType === StandardConst.ReceieverTypeOptions[1].value ? "primary" : "success"}
         />
        </>
      )
    },
    {
      Text: "Created By",
      Value: "CreaterName",
    },
    {
        Text:'Created on',
        Value:'CreatedAt',
        render:(dr)=>(
            <>{ dr.CreatedAt && beautifyLocalTime(dr.CreatedAt)}</>)
    },
    {
        Text:'Last updated on',
        Value:'UpdatedAt'   ,
        render:(dr)=>(
            <>{ dr.UpdatedAt && beautifyLocalTime(dr?.UpdatedAt)}</>
        )
    },

    {
      Text: "Action",
      cssClass: "text-center td-width-100",
      style:{width:'2px'},
      isVisiable:
        permission.ManageEdit ||
        permission.ManageDelete ||
        permission.ManageSend ||
        permission.ManageHistory ||
        permission.ManageView,
      render: (dr) => (
        <>
          {(dr.isReserved === 0 ||
            (dr.isReserved === 1 &&
              Roles[0] === StandardConst.SystemRole.SuperAdmin)) && (
            <span className="d-flex col-auto  ">
              <ActionButton
                IconTooltip={"Send Document"}
                IconColor='primary'
                IconName="EmailResend"
                disabled={!permission.ManageSend}
                id={`Documentsend${dr.DocumentTemplateId}`}
                onClick={() => {
                  SendDocument(dr);
                }}
              />
               <ActionButton
                IconTooltip="Document Sent History"
                IconName="History"
                IconColor='primary'
                disabled={!permission.ManageHistory}
                id={`DocumentHistory${dr.DocumentTemplateId}`}
                onClick={() => {
                  navigate("/DocumentHistory", {
                    state: { DocumentTemplateId: dr.DocumentTemplateId },
                  });
                }}
              />
            <ActionButton
            IconName='Download'
            IconTooltip='Download Document Template'
             onClick={() =>
                    window.open(
                      `${StandardConst.apiBaseUrl}/${dr.DocumentTemplatesPath}`,
                      "_blank"
                    )
                  }
            />
              <ActionButton
                IconTooltip={"Edit Document Template"}
                IconName="Edit"
                id={`DocumentSetting${dr.DocumentTemplateId}`}
                disabled={!permission.ManageEdit}
                onClick={() => {
                  fnEdit(dr.DocumentTemplateId);
                }}
              />
               <ActionButton
                IconTooltip="Delete Document Template"
                IconName="Delete"
                disabled={!permission.ManageDelete}
                id={`DocumentDelete${dr.DocumentTemplateId}`}
                onClick={() =>
                  ref.current.confirmAlert(
                    "Delete", //Confirm button text
                    "Are You Sure", // Text if Alert
                    "Do you want to delete document template '" +  dr.DocumentTemplateName + "' ?", // Message of Alert
                    dr // Endpoint to hit for delete
                  )
                }
              />
            </span>
          )}
        </>
      ),
    },
  ];

  return (
    <>
        {/* {filterComponent}  @shubham95shrivas please review this */}
        <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
        <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
        <StaticListComponent
          columns={columns}
          data={DocumentTemplates}
          bData={bData}
          MasterPageName={MasterPageName}
          noRecordCss="p-0"
          noRecordFound={
            <NoRecordTemplate
              headerValue={StandardConst.ManageDocumentTempalteNoResult}
              subHeaderValue={StandardConst.ManageDocumetTempalateSubHeaderRole}
              imageUrl={StandardConst.imageNoRecordsFound}
              actionButton={
                <>
                  {permission.ManageAdd && (
                    <Button
                      id="NoRecordFoundAddEvent"
                      variant="outline-primary"
                      onClick={() => fnEdit(0)}
                    >
                      Add {AddButtonText}
                    </Button>
                  )}
                </>
              }
            />
          }
          onAddEvent={() => fnEdit(0)}
          IsAddButtonVisible={permission?.ManageAdd}
          AddButtonText={AddButtonText}
          isSearchRequired={true}
          allowSerialNo={true}
        />
     <AddEditDocument
            callBackEvent={() => fetchDocumentTempalete()}
            ref={addEditModalRef}
          ></AddEditDocument>
    </>
  );
};

export default ManageDocument;
