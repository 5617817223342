import React from 'react'
import Button from 'react-bootstrap/esm/Button';
import './page.css';
import unsubscribeImage from '../../Images/unSubscribe (2).png'
import { WebService } from '../../Services/WebService';
import { StandardConst } from '../../Services/StandardConst';
import { WSSuccessAlert } from '../../Services/WSAlert';
import { useSearchParams } from 'react-router-dom';
import HeaderComponent from '../Layout/HeaderComponent';
import JobHeader from '../Recuritment/JobHeader';
import Header from './Header';

function EmailUnsubscribe() {
  const [searchParams] = useSearchParams();
   console.log('called')

    const onUnsubscribe=async()=>{
        let val={}
        val.StatusId= StandardConst.Status.Inactive;
         const groups = await WebService({
                  endPoint: `CommonUtility/sendmailtousers?Email=${searchParams.get("email")}`,
                  method:'put',
                  body:val
                })
                WSSuccessAlert('','Unsubsribed')
    }
  return  (
    <>
    <Header/>
    <div className="unsubscribe-container d-flex align-items-center justify-content-center">
      <div className="unsubscribe-card p-4 text-center">
        {/* Sad Envelope Icon */}
        <div className="icon-container pt-3">
          <img src={unsubscribeImage} alt='svg'/>
        </div>

        {/* Title */}
        <h2 className="fw-bold mt-3">Are you sure about unsubscribing?</h2>

        {/* Description */}
        <p className="text-muted">
          If you unsubscribe now, you might miss nice deals and useful hints from WiseOfficeDay.Com!
        </p>

        {/* Buttons */}
        <div className="d-flex justify-content-center gap-3">
          <Button variant="contained" className='btn ' style={{backgroundColor:'rgb(255,144,0)',color:'white',fontWeight:'600'}} >
            I'd rather stay
          </Button>
          <Button variant="text" color="warning" onClick={()=>{onUnsubscribe()}}  >
            Unsubscribe me →
          </Button>
        </div>
      </div>
    </div>
    </>
  );
}
export default EmailUnsubscribe
