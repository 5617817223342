import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/esm/Button";

import { values } from 'underscore';
import AddEditEmailTemplateType from './AddEditEmailTemplateType';
import { ActionPermission, PageInfo } from '../PageInfo';
import { WebService } from '../../Services/WebService';
import ActionButton from '../../Services/ActionButton';
import SnackbarComponent from '../../Services/SnackbarComponent';
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { StandardConst } from '../../Services/StandardConst';
import DeleteConfirmAlert from '../../Services/AlertComponent';
import CloneEmailTemplate from './CloneEmailTemplate';
import { useNavigate } from 'react-router-dom';

const ManageMailTemplate = () => {

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const navigate = useNavigate()
    PageInfo({ pageTitle: "Manage Mail Template" });

    // need to update permissions
    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Email Template - Add"),
            ManageEdit: ActionPermission("Email Template - Edit"),
            ManageDelete: ActionPermission("Email Template - Delete"),
            ManageView: ActionPermission("Email Template - View"),
        });
    }, [permissionList]);



    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Manage Mail Template";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();
    const cloneModalRef=useRef();

    const AddButtonText = "Mail Template";
    const dispatch = useDispatch();
    const [deletedName, setDeletedName] = useState(null);

    const fetchEmailTemplate = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/staticemailtemplate`,
            dispatch,
        });
        setRecords(data.reverse());
    }

    useEffect(() => {
        fetchEmailTemplate();
    }, []);


    const [bData,] = React.useState([
        {
            title: "Master",
            hrefLink: "#",
        },
        {
            title: "Mail Templates",
            hrefLink: "#",
        },
    ]);

 

    const onDelete = async (Details) => {
        const data = Details.split(',');
        const TemplateId = data[0];
        setDeletedName(data[1]);
        await WebService({
            endPoint: `CommonUtility/staticemailtemplate?TemplateId=${TemplateId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchEmailTemplate();
    };

    const columns = [
      {
        Text: "Title",
        Value: "Title",
      },
      {
        Text: "Subject",
        Value: "Subject",
      },
      {
        Text: "SqlQuery",
        Value: "SqlQuery",
      },
      {
        Text: "Status",
        values:'Status',
        render: (dr) => (
          <>
            {dr.Status == 18
              ? 'active'
              : 'inactive'}
          </>
        ),
      },
      {
        Text: "Action",
        cssClass: "text-center td-width-100",
        isVisiable:
          permission.ManageEdit ||
          permission.ManageDelete ||
          permission.ManageAdd,
        render: (dr) => (
          <>

            <span  className='d-flex flex-row col-auto'>
            <ActionButton
                IconName="clone"
                onClick={() => fnClone(dr.TemplateId)}
              />
              {(Roles[0] === StandardConst.SystemRole.SuperAdmin || dr.IsReserved === 0) && (
                <span className="d-flex col-auto">
                  <ActionButton
                    onClick={() => fnEdit(dr.TemplateId)}
                    IconName="Edit"
                    IconTooltip={"Edit Mail Template"}
                    disabled={!permission.ManageEdit}
                    id={`btnUserTypeEdit_${dr.TemplateId}`}
                  />
                  <ActionButton
                    onClick={(e) =>
                      ref.current.confirmAlert(
                        "Delete", //Confirm button text
                        "Are You Sure", // Text if Alert
                        "Do you want to delete " + dr.Title, // Message of Alert
                        `${dr.TemplateId},${dr.Title}` // Endpoint to hit for delete
                      )
                    }
                    IconName="Delete"
                    disabled={!permission.ManageDelete}
                    IconTooltip={"Delete Manage Mail Template"}
                    id={`btnMailTemplateDelete_${dr.TemplateId}`}
                  />
                  <ActionButton
                    onClick={() => {
                      const blob = new Blob([dr.Body], { type: "text/html" });
                      const url = URL.createObjectURL(blob);
                      // Open the Blob URL in a new tab
                      window.open(url, "_blank");
                    }}
                    IconName={"View"}
                    id={`btnMailTemplateView_${dr.TemplateId}`}
                    IconColor={"primary"}
                    disabled={!permission.ManageView}
                    IconTooltip={"View Mail Template"}
                  />
                </span>
              )}
             
            </span>
          </>
        ),
      },
    ];

    const fnEdit = async (id) =>   navigate('/AddEditMailTemplate', { state: { id: id || 0 } });
    
    const fnClone=async(id)=> await cloneModalRef.current.openModal(id)

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={`Mail Template ${(deletedName !== null) ? deletedName : ""} Deleted successfully`} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.UserTypeheaderValueNoResults}
                        subHeaderValue={StandardConst.UserTypeSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={true}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditEmailTemplateType
                callBackEvent={async () => await fetchEmailTemplate()}
                ref={addEditModalRef}
            ></AddEditEmailTemplateType>
            <CloneEmailTemplate
                callBackEvent={async () => await fetchEmailTemplate()}
                ref={cloneModalRef}

            />
        </>
    )
}

export default ManageMailTemplate