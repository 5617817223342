import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { ActionPermission, PageInfo } from '../PageInfo';
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/esm/Button";
import AddEditAssets from './AddEditAssets';
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Alert} from "@mui/material";
import QRCodeGeneratorDownloadComponent from '../../Services/QRCodeGeneratorDownloadComponent';

const ManageAssets = () => {
    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: "Manage Assets" });
    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Assets - Add"),
            ManageEdit: ActionPermission("Assets - Edit"),
            ManageDelete: ActionPermission("Assets - Delete"),
            ManageQRCode: ActionPermission("Assets - QR Code"),
        });
    }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Manage Assets";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();
    const navigate = useNavigate();
    const AddButtonText = "Assets";
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.companyInfo ?? {});

    const [deletedName, setDeletedName] = useState(null);

    const onDelete = async (Details) => {
        const data = Details.split(',');
        const AssetId = data[0];
        setDeletedName(data[1]);
        await WebService({
            endPoint: `CommonUtility/assets?AssetId=${AssetId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAssets();
    };

    const fetchAssets = async () => {
        const data = await WebService({
            endPoint: `AssetAssignment/fetchAllAssets`,
            dispatch,
        });
        setRecords(data);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
        fetchAssets();
    }, []);

    const [bData ] = React.useState([
        {
            title: "Assets Management",
            hrefLink: "#",
        },
        {
            title: "Manage Assets",
            hrefLink: "#",
        },
    ]);

    const GenerateQRRef = useRef();

    const columns = [
        {
            Text: "Asset Name",
            Value: "AssetName",
        },
        {
            Text: "Description",
            Value: "Description"
        },
        {
            Text: "Asset Type Name",
            Value: "AssetTypeName",
            render: (dr) => {
                return (
                    <>
                    <i className={`me-2 ${dr.AssetTypeIcon}`}></i><span>{dr.AssetTypeName}</span>
                    </>
                )
            }
        },
        {
            Text: "Asset Number",
            Value: "AssetNumber"
        },
        {
            Text: "Asset Tag Id",
            Value: "AssetTagId"
        },
        {
            Text: "Status",
            Value: "Status",
            render: (dr) => {
                const foundStatus = StandardConst?.AssetStatusDropdown?.find(item => item?.value === dr?.Status);
                return (
                    <>
                        {foundStatus?.text} { foundStatus.Status}
                    </>
                )
            }
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <div className="d-flex">
                    <ActionButton
                        onClick={() => fnEdit(dr.AssetId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Asset"}
                        id={`btnAssetEdit_${dr.AssetId}`}
                    />
                    <ActionButton
                        onClick={async () => await GenerateQRRef.current.openModal(dr.AssetId)}
                        IconName="QR"
                        IconTooltip={"QR Code"}
                        id="btnAssetQRCode"
                        disabled={!permission.ManageQRCode}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.AssetName, // Message of Alert
                                `${dr.AssetId},${dr.AssetName}`  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Asset"}
                        id={`btnAssetDelete_${dr.AssetId}`}
                    />
                </div>
            ),
        },
    ];

    const fnEdit = (id) => navigate("/AddAssetInformation", { state: { AssetId: id}});

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={`Asset ${(deletedName!== null) ? deletedName : ""} Deleted successfully`} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.AssetheaderValueNoResults}
                        subHeaderValue={StandardConst.AssetSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditAssets
                callBackEvent={async () => await fetchAssets()}
                ref={addEditModalRef}
            ></AddEditAssets>
            <GenerateQR ref={GenerateQRRef} />
        </>
    )
}


const GenerateQR = forwardRef(({ }, ref) => {
    const [show, setShow] = useState(false);
    const [qrData, setQrData] = useState(false);
    const handleClose = () => setShow(false);
    useImperativeHandle(ref, () => ({
        openModal: async (AssetId) => {
            const qrData = `AssetId:${String(AssetId)}`
            setQrData(qrData);
            setShow(true);
        },
    }));

    return (
        <Modal
            size="xs"
            centered
            show={show}
            onHide={handleClose}
            className="container-fluid"
        >
            <Modal.Header closeButton>
                <Modal.Title>QR Code for Assets</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 m-0">
                <Alert severity="success">
                    Download and paste this QR Code at the Asset Assignment. Employee or User
                    can scan this QR Code and to assign asset.
                </Alert>
                <div className="p-3 m-0">
                    <QRCodeGeneratorDownloadComponent data={qrData} showWodLogo={false} showLogo={false} EventLogo={null} Text="Powered by wiseofficeday.com" />
                </div>
            </Modal.Body>
        </Modal>
    );
});

export default ManageAssets