import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const FileViewerModal = ({ fileUrl }) => {
  const [show, setShow] = useState(false);

  const isPdf = fileUrl?.toLowerCase().endsWith(".pdf");

  return (
    <div>
      {/* Small Image (Click to Open Modal) */}
      <img
        src={fileUrl} // Use thumbnail if provided, otherwise show full image
        alt="Preview"
        style={{ width: "200px", borderRadius: "8px", cursor: "pointer" }}
        onClick={() => setShow(true)}
      />

      {/* Bootstrap Modal */}
      <div className={`modal fade ${show ? "show d-block" : "d-none"}`} tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">File Preview</h5>
              <button type="button" className="btn-close" onClick={() => setShow(false)}></button>
            </div>
            <div className="modal-body text-center">
              {isPdf ? (
                <iframe src={fileUrl} width="100%" height="500px" title="PDF Viewer"></iframe>
              ) : (
                <img src={fileUrl} alt="Preview" className="img-fluid" />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal backdrop to close on outside click */}
      {show && <div className="modal-backdrop fade show" onClick={() => setShow(false)}></div>}
    </div>
  );
};

export default FileViewerModal;
