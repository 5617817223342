import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WebService } from '../../Services/WebService';
import { Container } from '@mui/material';

function Chatpage() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
  const [secondPersonId,setsecondPersonId]=useState()
  
  const SendMessage = async () => {
    console.log(secondPersonId)
    let values = {};
    values.FromId = EmployeeId;
    values.ToId = secondPersonId; 
    values.Message = input;

    await WebService({
      endPoint: `CommonUtility/Chat/`,
      body: values
    });
  };
console.log(secondPersonId)
  const ReceiveMessages = async () => {
    const data = await WebService({
      endPoint: `chat/getLetestMessage?ToId=${secondPersonId}`,
    });

    // Assuming data is an array of message objects
    if (Array.isArray(data)) {
      const formattedMessages = data.map(msg => ({
        sender: msg.FromId === EmployeeId ? 'You' : 'Other Person',
        text: msg.Message,
      }));
      setMessages([...messages, ...formattedMessages]);
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if(secondPersonId)
  //     {
  //       ReceiveMessages();
  //     }       
  //   }, 10000); // Runs every 5 seconds

  //   return () => clearInterval(interval); // Cleanup on component unmount
  // }, []);

  const handleSendMessage = () => {
    if (input.trim() !== '') {
      setMessages([...messages, { sender: 'You', text: input }]);
      setInput('');
      SendMessage();
    }
  };

  return (
    <>
         <Container fluid className="base-container">
         <div className="flex flex-col h-screen bg-gray-100">
      {/* Header */}
      <header className="text-white p-4 shadow-lg">
        <h1 className="text-lg font-bold">Chat Application</h1>
      </header>
     <button className='btn btn-primary' onClick={ReceiveMessages}>receieve messages</button>
    recieve messages for  <button onClick={()=>setsecondPersonId(846)}>virat</button> <button onClick={()=>setsecondPersonId(112)}>shubham</button>
      {/* Chat Area */} 
      <div className="flex-1 overflow-y-auto p-4 bg-white">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.sender === 'You' ? 'justify-end' : 'justify-start'
            } mb-2`}
          >
            <div
              className={`p-3 rounded-lg ${
                message.sender === 'You'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-black'
              }`}
            >
              <span className="block text-sm font-semibold">
                {message.sender}
              </span>
              <span className="block"> {" : "}{message.text}</span>
            </div>
          </div>
        ))}
      </div>

      {/* Input Section */}
      <div className="bg-gray-200 p-4 flex items-center">
        <input
          type="text"
          className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Type a message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <button
          className="ml-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          onClick={handleSendMessage}
        >
          Send
        </button>
      </div>
    </div>
          </Container>
    </>
   
  );
}

export default Chatpage;
