import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Services/authSlice";
import _, { chain, sortBy } from "underscore";
import Accordion from "react-bootstrap/Accordion";
import "./Sidemenu.css";
import { Avatar } from "@mui/material";
import { ActionPermission } from "../PageInfo";
const Menu = ({ dataSet, childOf }) => {
const location=useLocation()
  const [menuCounts, setMenuCounts] = useState({});
  // Simulating asynchronous fetching of counts from the database
  const fetchCountsFromDB = async (menuItem) => {
    var count = 0;
    if(menuItem !== undefined && menuItem.LinkedTable !== null && menuItem.LinkedTable !== undefined) {
      count = await WebService({
                          endPoint: `CommonUtility/getCount/${menuItem.LinkedTable}?CompanyId=${StandardConst.YesNo.Yes}${(menuItem.ExtraCondition !== null) && (menuItem.ExtraCondition !== undefined) ? `&${menuItem.ExtraCondition}` : ''}`,
                          method: "GET",
                        });

      setMenuCounts((prevCounts) => ({
        ...prevCounts,
        [menuItem.MenuText]: count,
      }));
    }
  };

  useEffect(() => {
    dataSet.forEach((menuItem) => {
      fetchCountsFromDB(menuItem);
    });
  },[dataSet]);
  
  return (
    <>
      <Accordion as={"ul"} className="navbar-nav">
        {chain(dataSet ?? [])
          .where({ ParentId: childOf })
          .sortBy((s) => s.OrderNo)
          .map((m, i) => (
            <React.Fragment key={i}>
              {m.Route !== null && (
                <Accordion.Item
                  style={{ border: "none" }}
                  eventKey={m.MenuId}
                  as={"li"}
                  className="nav-item"
                  id={`control_${m.Route}`}
                >
                  <Link
          className={`nav-link ${location.pathname == '/'+m.Route ? "active" : ""}`}
          to={`/${m.Route}`}
                    onClick={() => {
                      document.getElementById("MenuBtn").click();
                    }}
                  >
                    {" "}
                    <i className={m.Icon}></i> {m.MenuText} {menuCounts[m.MenuText] ? `( ${menuCounts[m.MenuText]} )` : ""}
                  </Link>
                </Accordion.Item>
              )}
              {m.Route === null && (
                <Accordion.Item
                  eventKey={m.MenuId}
                  as={"li"}
                  id={`control_${m.MenuText}`}
                >
                  <Accordion.Header className="accordion-header-custom">
                    <span className="nav-link p-0 m-0">
                      <i className={m.Icon}></i>
                      {m.MenuText}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body className="sub-menu-padding-left">
                    <Menu dataSet={dataSet} childOf={m.MenuId} />
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </React.Fragment>
          ))
          .value()}
      </Accordion>
    </>
  );
};
function SideBarComponent() {
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const fnLogout = async () => {
    // await WebService({ dispatch, endPoint: "LogOut/Clear" });
    dispatch(logout());
  };
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const menu = useSelector((state) => state.auth.MenuList);
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const loggedNonCompanyUser = useSelector((s) => s.auth.LoggedCustomer ?? 0);
  const location =useLocation()
  const [permission, SetPermission] = useState({});
  const [unreadMessageCount,setunreadMessageCount]=useState(0)
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);

  useEffect(() => {
    SetPermission({
      JobApplicantProfile: ActionPermission("Profile - Job Applicant"),
    });
    fetchUnreadMessageCount()
  }, [permissionList]);

  const [nonCompanyUserData, setNonCompanyUserData] = useState({});
  const fetchProfile = async () => {
    await WebService({
      dispatch,
      endPoint: `CommonUtility/users?select=FullName, ProfileImage, "Job Applicant" Designation&where=UserId eq ${loggedNonCompanyUser}`,
    }).then((r) => setNonCompanyUserData(r.length > 0 ? r[0] : {}));
  };

  useEffect(() => {
    if(Object.keys(CompanyInfo).length === 0 && loggedNonCompanyUser){
      fetchProfile();
    }
  }, [CompanyInfo, loggedNonCompanyUser]);

  const fetchUnreadMessageCount = async () => {
    await WebService({
      dispatch,
      endPoint:'chat/TotalCountOfUnreadMessages',
    }).then((r) => setunreadMessageCount(r));
  };

  return (
    <nav
      className="navbar show navbar-vertical navbar-expand-lg px-0 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg"
      id="navbarVertical"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler ms-n2"
          type="button"
          onClick={() => setShow(!show)}
          aria-controls="sidebarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          id="MenuBtn"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <a className="navbar-brand me-0" onClick={() => navigate("/Dashboard")}>
          <div
            className="d-flex justify-content-around align-items-center flex-row  text-wrap ps-3 "
            style={{ maxWidth: "260px" }}
          >
            {Object.keys(nonCompanyUserData).length > 0 ? (
              <>
                <Avatar
                  className="object-fit-cover "
                  sx={{ width: 95, height: 50 }}
                  alt={(nonCompanyUserData ?? {}).FullName}
                  variant="rounded"
                  src={
                    (CompanyInfo ?? {}).Logo !== ""
                      ? `${StandardConst.apiBaseUrl}/uploads/${
                          (nonCompanyUserData ?? {}).ProfileImage ??
                          "user_placeholder.jpg"
                        }`
                      : ``
                  }
                />
                <div className="ms-2">
                  <h6 className="mb-0 text-primary">
                    {(nonCompanyUserData ?? {}).FullName}
                  </h6>{" "}
                </div>
              </>
            ) : (
              <>
                <Avatar
                  className="object-fit-cover "
                  sx={{ width: 95, height: 50 }}
                  alt={(CompanyInfo ?? {}).CompanyName}
                  variant="rounded"
                  src={
                    (CompanyInfo ?? {}).Logo !== ""
                      ? `${StandardConst.apiBaseUrl}/uploads/${
                          (CompanyInfo ?? {}).Logo ?? "user_placeholder.jpg"
                        }`
                      : ``
                  }
                />
                <div className="ms-2">
                  <h6 className="mb-0 text-primary">
                    {(CompanyInfo ?? {}).CompanyName}
                  </h6>{" "}
                </div>
              </>
            )}
          </div>
          <div className="px-3 h6 text-truncate" style={{ maxWidth: "250px" }}>
            {/* <small> Welcome, User Name</small> */}
          </div>
          <hr className="d-none d-md-block"></hr>
        </a>
        <div className="navbar-user d-lg-none">
          <div className="dropdown">
            <a
              href="#"
              id="sidebarAvatar"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></a>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="sidebarAvatar"
            >
              <a href="#" className="dropdown-item">
                Profile
              </a>
              <a href="#" className="dropdown-item">
                Settings
              </a>
              <a href="#" className="dropdown-item">
                Billing
              </a>
              <hr className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                Logout
              </a>
            </div>
          </div>
        </div>
        <div
          className="collapse navbar-collapse"
          style={show ? { display: "block" } : { display: "none" }}
          id="sidebarCollapse"
        >
          <Menu dataSet={menu} childOf={null}></Menu>
          <hr className="navbar-divider my-2 opacity-20" />
          <ul className="navbar-nav">
            {/* yaha per employee ka profile ayega */}
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname === "/newChat" ? "active" : ""
                } d-flex flex-row justify-content-between`}
                id="btnUserProfileMenu"
                to="/newChat"
                onClick={() => {
                  document.getElementById("MenuBtn").click();
                }}
              >
                <span>
                  <i className="fa fa-comment"></i> Chat 
                </span>

                {unreadMessageCount > 0 && (
                  <span
                    className="text-white rounded-full inline-flex my-auto text-center justify-center text-small items-center"
                    style={{
                      width: "20px",
                      height: "20px",
                      lineHeight: "20px",
                      borderRadius: "50%",
                      fontSize: "small",
                      backgroundColor: "#d5ac32",
                    }}
                  >
                    {unreadMessageCount}
                  </span>
                )}
              </Link>
            </li>

            {loggedUser !== StandardConst.YesNo.No && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname == "/Common/Profile" ? "active" : ""
                  }`}
                  id="btnUserProfileMenu"
                  to="/Common/Profile"
                  onClick={() => {
                    document.getElementById("MenuBtn").click();
                  }}
                >
                  <i className="fa fa-user"></i> User Profile
                </Link>
              </li>
            )}

            {/* yaha per Customer ka profile ayega */}
            {loggedUser === StandardConst.YesNo.No && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname == "/Common/CustomerProfile"
                      ? "active"
                      : ""
                  }`}
                  id="btnUserProfileMenu"
                  to="/Common/CustomerProfile"
                >
                  <i className="fa fa-user"></i> Profile
                </Link>
              </li>
            )}

            {permission?.JobApplicantProfile && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname == "/EditApplicantProfile" ? "active" : ""
                  }`}
                  id="btnUserProfileMenu"
                  to="/EditApplicantProfile"
                >
                  <i className="fa fa-user"></i> Job Profile
                </Link>
              </li>
            )}

            <li className="nav-item">
              <Link
                className={`nav-link ${
                  location.pathname == "/Common/ChangePassword" ? "active" : ""
                }`}
                id="btnChangrPasswordMenu"
                to="/Common/ChangePassword"
                onClick={() => {
                  document.getElementById("MenuBtn").click();
                }}
              >
                <i className="fa fa-key"></i> Change Password
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                id="btnlogoutMenu"
                onClick={() => fnLogout()}
              >
                <i className="fa fa-sign-out"></i> Logout
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className="nav-link"
                id="btnErrorlogComponent"
                to="/Common/Error"
              >
                <i className="fa fa-key"></i> Error
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default SideBarComponent;
