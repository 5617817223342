import Button from "react-bootstrap/Button";
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
function Header() {
   
  return (
    <nav className="navbar navbar-expand-lg  py-2 bg-white  rounded-bottom">
                  <div class="container-fluid">
                      <Link class="navbar-brand" to='https://wiseofficeday.com/'>
                          <img src="https://assets.wiseofficeday.com/wod/project_images/WiseOfficeDayLogo.png" class="img-responsive rounded mx-auto d-block" alt="logo" style={{width: "200px", height:"auto"}} />
                      </Link>
                  </div>
              </nav>
  )
}

export default Header
